<template>
  <div id="cash-design">
    <v-container class="fill-height">
      <v-row justify="center" align="center">
        <!-- 좌 -->
        <v-col cols="12" md="5" order-md="2">
          <div class="left" :class="{ mobile: !$vuetify.breakpoint.smAndUp }">
            <!-- <h2>CASH DESIGN</h2> -->
            <v-btn text plain :ripple="false" class="cash-design">
              CASH DESIGN
            </v-btn>
          </div>
        </v-col>

        <!-- 우 -->
        <v-col cols="12" md="5" order-md="1">
          <div class="right" :class="{ mobile: !$vuetify.breakpoint.smAndUp }">
            <!-- 1 -->
            <div class="each">
              <i class="xi-search xi-3x each-icon" />
              <h4>CEO 급여 적정성 분석</h4>
            </div>

            <!-- 2 -->
            <div class="each">
              <i class="xi-calculator xi-3x each-icon" />
              <h4>차등배당 활용</h4>
            </div>

            <!-- 3 -->
            <div class="each">
              <i class="xi-piggy-bank xi-3x each-icon" />
              <h4>합법적 퇴직금 마련</h4>
            </div>

            <!-- 4 -->
            <div class="each">
              <i class="xi-percent xi-3x each-icon" />
              <h4>감자를 통한 자금 유동화 전략</h4>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  data: () => ({})
}
</script>

<style lang="scss" scoped>
#cash-design {
  height: 100vh;

  // 좌
  .left {
    text-align: right;

    h2 {
      font-size: 48px;
      font-weight: 800 !important;
      color: $color-black2;
    }

    .cash-design {
      font-size: 48px;
      font-weight: 800 !important;
      padding: 0;
      position: relative;
      cursor: default;

      &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: -16px;
        width: 100%;
        background-color: $color-black;
        height: 4px;
      }
    }

    &.mobile {
      text-align: center;

      h2 {
        font-size: 24px;
        margin-bottom: 40px;
      }

      .cash-design {
        font-size: 24px;
        margin-bottom: 40px;

        &::after {
          bottom: -4px;
        }
      }
    }
  }

  // 우
  .right {
    .each {
      display: flex;
      align-items: center;
      margin-bottom: 60px;

      .each-icon {
        margin-right: 16px;
      }

      h4 {
        font-size: 32px;
      }
    }

    &.mobile {
      padding: 0 16px;

      .each {
        padding-left: 24px;
        .each-icon {
          font-size: 2em;
        }

        h4 {
          font-size: 16px;
        }
      }
    }
  }
}
</style>
